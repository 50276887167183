import './Marketing.css';
import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Marketing() {
    const [text1, setText1] = useState("At Auburn, our journey exemplifies passion and innovation.");
    const [text2, setText2] = useState("From humble beginnings to a leading digital marketing powerhouse, our story is built on creativity, resilience, and an unwavering commitment to excellence.");
    const [text3, setText3] = useState("We transform visions into reality, crafting narratives that resonate and inspire, consistently pushing the boundaries of what's possible in digital marketing.")
    const [storyColor, setStoryColor] = useState('white');
    const [processColor, setProcessColor] = useState('grey');
    const [teamColor, setTeamColor] = useState('grey');
    const [isUnderlined, setIsUnderlined] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleToggle = () => {
        setMenuOpen(!isMenuOpen);
    };

    function setProcess() {
        setText1("Our process is a symphony and which always results in a exceptional results");
        setText2("At Auburn, we blend data-driven insights with artistic flair to design campaigns that captivate and convert.")
        setText3("Every step, from brainstorming to execution, is meticulously crafted to deliver exceptional results and exceed expectations.")
        setProcessColor('white');
        setStoryColor('grey');
        setTeamColor('grey');
    }

    function setStory() {
        setText1("At Auburn, our journey is a testament to passion and innovation.");
        setText2("From humble beginnings to a leading digital marketing powerhouse, our story is built on creativity, resilience, and an unwavering commitment to excellence. We transform visions into reality, crafting narratives that resonate and inspire.");
        setStoryColor('white');
        setProcessColor('grey');
        setTeamColor('grey');
    }

    function setTeam() {
        setText1("Meet the heart of Auburn. The team behind every successful projects");
        setText2("A dynamic team of visionaries and experts, united by a shared passion for digital marketing, brings diverse skills and fresh perspectives to the table.");
        setText3("Together, we push boundaries, drive innovation, and deliver success for our clients.");
        setTeamColor('white');
        setStoryColor('grey');
        setProcessColor('grey');
    }

    const buttonStyle = (color) => ({
        color: color,
        border: 'none',
        borderBottom: `2px solid ${color === 'grey' ? 'grey' : 'white'}`,
        padding: '10px 20px',
        cursor: 'pointer',
        textDecoration: isUnderlined ? 'underline' : 'none',
    });

    function openWhatsapp() {
        window.open("https://wa.me/9711541313?text=Hello%20Auburn,%20I%20am%20interested%20in%20your%20marketing%20services", "_blank");
    }

    function openInsta() {
        window.open("https://www.instagram.com/auburnstudio__?igsh=dTE0aGF2YmFnN2Y0")
    }

    return (
        <>
            <nav className="navbar">
                <Link to="/">
                    <img src="logo.png" alt="Company Logo" className="logo" />
                </Link>
                <div className={`nav-links ${isMenuOpen ? 'mobile' : ''}`}>
                    <a href="#about">About <span className="nav-co">us</span></a>
                    <Link to="/production">
                        Production <span className="nav-co">04</span>
                    </Link>
                    <a href="#service">Service <span className="nav-co">06</span></a>
                    <a href="#contact">Contact <span className="nav-co">us</span></a>
                </div>
                <div className="hamburger" onClick={handleToggle}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </div>
            </nav>

            <div className='home-pg'>
                <div className='home-pg1'>
                    1.2M
                    <span className='nav-co'>CUSTOMERS</span>
                </div>
                <div className='home-pg2'>
                    WE BUILD <br /> EXPERIENCES <br /> FOR BRANDS.
                </div>
            </div>
            <div className='talk'>
                <div className='talk1'>
                    <button>CONNECT ↗</button>
                </div>
                <div className='talk2'>
                    <p>AUBURN is a premier digital marketing firm specializing in comprehensive strategies, in-house content creation, and innovative campaigns. With expertise in traditional marketing, SEO, SEM, influencer marketing, and brand recognition, AUBURN elevates businesses through tailored, impactful solutions.</p>
                </div>
            </div>
            <div className='home-pg' id="about">
                <div className='home-pg1'>
                    <span className='nav-co'>COMPANY</span>
                </div>
                <div className='home-pg3'>
                    We are constantly rethinking the future by creating the products. We are a creative agency based in Milan, we provide versatile visual identities for every type of company.
                </div>
            </div>
            <div className='story'>
                <div className='story1'>
                    <img src='photo-mark.jpg' className='responsive-image' alt='Marketing Image'></img>
                </div>
                <div className='story2'>
                    <button style={buttonStyle(storyColor)} onClick={setStory}>STORY</button>
                    <button style={buttonStyle(processColor)} onClick={setProcess}>PROCESS</button>
                    <button style={buttonStyle(teamColor)} onClick={setTeam}>OUR TEAM</button>
                </div>
                <div className='story3'>
                    <p>{text1}</p>
                    <p>{text2}</p>
                    <p>{text3}</p>
                </div>
            </div>
            <div className='home-pg' style={{ backgroundColor: '#EFEFEF', width: '100%', marginTop: '5rem' }} id="service">
                <div className='home-pg1'>
                    <span className='nav-co' style={{ color: 'black' }}>SERVICES</span>
                </div>
                <div className='home-pg3' style={{ color: 'black', fontWeight: 'bolder', paddingRight: '3rem' }}>
                    We create experiences and turn ideas into reality
                    <div style={{ fontWeight: 'lighter', fontSize: '1rem', paddingTop: '3rem' }}>
                        <p>At Auburn, our journey exemplifies passion and innovation. <br /> From humble beginnings to a leading digital marketing <br /> powerhouse, our story is built on  creativity, resilience, and <br /> excellence.</p>
                    </div >
                    <div style={{ fontWeight: 'lighter', fontSize: '1rem' }}>
                        <p>We transform visions into reality with data-driven <br /> strategies and engaging in-house content. Traditional marketing <br /> channels and expert SEO and SEM techniques broaden <br /> your reach and visibility.</p>
                    </div>
                    <div style={{ fontWeight: 'lighter', fontSize: '1rem' }}>
                        <p>Our influencer marketing amplifies your brand, while our <br /> graphic design and ad campaigns leave lasting impressions.</p>
                    </div>
                </div>
            </div>
            <div className='services' style={{ backgroundColor: '#EFEFEF' }}>
                <div className='services1'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>IN HOUSE CONTENT CREATION</h3>
                </div>
                <div className='services2'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>ADS SEO</h3>
                </div>
                <div className='services3'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>INFLUENCER MARKETING </h3>
                </div>
                <div className='services4'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>PR EVENTS</h3>
                </div>
            </div>
            <div className='services' style={{ backgroundColor: '#EFEFEF' }}>
                <div className='services5'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>TRADITIONAL MARKETING</h3>
                </div>
                <div className='services6'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>CAMPAIGNS</h3>
                </div>
                <div className='services7'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>GRAPHICS </h3>
                </div>
                <div className='services8'>
                    <img src='services-img.png' />
                    <h3 style={{ fontWeight: 'lighter', color: 'black' }}>MARKETING STRATEGIES</h3>
                </div>
            </div>
            <div className='home-pg' id="case-studies">
                <div className='home-pg1'>
                    <span className='nav-co'>Everything begins with <br /> to emotions</span>
                </div>
                <div className='home-pg3'>
                    Highlighting Our Most Impactful Projects, See the Success Stories We’ve Created
                </div>
            </div>
            <div className='videos'>
                <div className='videos1'>
                    <div className='video-container'>
                        <div>
                            <video className='small-video' src='service1.mp4' autoPlay muted loop playsInline></video>
                        </div>
                        <div className='greys'>
                            <p className='grey'>KULDEEP YADAV</p>
                            <p className='grey'>ⓒ 2024</p>
                        </div>
                    </div>
                    <div className='video-container'>
                        <video className='small-video' src='service2.mp4' autoPlay muted loop playsInline></video>
                        <div className='greys'>
                            <p className='grey'>SOULNEWDELHI</p>
                            <p className='grey'>ⓒ 2024</p>
                        </div>
                    </div>
                </div>
                <div className='videos2'>
                    <div className='video-container'>
                        <video className='small-video' src='service3.mp4' autoPlay muted loop playsInline></video>
                        <div className='greys'>
                            <p className='grey'>GALAZIO DELHI</p>
                            <p className='grey'>ⓒ 2024</p>
                        </div>
                    </div>
                    <div className='video-container'>
                        <video className='small-video' src='service4.mp4' autoPlay muted loop playsInline></video>
                        <div className='greys'>
                            <p className='grey'>CARTIER</p>
                            <p className='grey'>ⓒ 2024</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='case'>
                <button onClick={openInsta}>ALL CASE STUDIES</button>
            </div>

            <div className='contact' id='contact'>
                <div className='contact1'>
                    <p>Let's shape & build your brand together</p>
                </div>
                <div className='contact2'>
                    <button onClick={openWhatsapp}>Let's Talk</button>
                </div>
            </div>
            <div className='footer'>
                <div>
                    <div><h1>Auburn</h1></div>
                    <div><p className='grey'>ⓒ Copyright 2024. All rights reserved</p></div>
                </div>
                <div></div>
            </div>
        </>
    );
}

export default Marketing;
