import "./Production.css";
import React, { useEffect, useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

function Production() {
  const containerRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
        const isScrolledPast = rect.bottom < 0 || rect.top > window.innerHeight;

        setIsScrolled(isInViewport && !isScrolledPast);
      }
    };

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);
    // Initial check
    handleScroll();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };
  function openWhatsapp() {
    window.open("https://wa.me/9711541313?text=Hello%20Auburn,%20I%20am%20interested%20in%20your%20marketing%20services", "_blank");
}
  return (
    <div>
      <nav className="navbar">
        <Link to="/">
          <img src="logo.png" alt="Company Logo" className="logo" />
        </Link>
        <div className={`nav-links ${isMobile ? "mobile" : ""}`}>
          <a href="#about">
            About <span className="nav-co">us</span>
          </a>
          <a href="#service">
            Service <span className="nav-co">06</span>
          </a>
          {/* <a href="#case-studies">
            Case Studies <span className="nav-co">04</span>
          </a> */}
            <Link to="/marketing">
            Marketing <span className="nav-co">06</span>
          </Link>
          <a href="#contact">
            Contact <span className="nav-co">us</span>
          </a>
        </div>
        <div className="hamburger" onClick={handleToggle}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      <div className="main">
        <div className="heading">
          <div className="main-heading">
            Elevate your Revenue
            <br /> with our Experts
          </div>
          <div className="sub-heading">
            We transform your vision into compelling <br></br>experiences that
            drive results.
          </div>
          <div className="but-head">
            <a href="#talk" className="but-heading">Get Started</a>
          </div>
        </div>
        <div className="main-img">
          <div className="mi-flex1" id="col-1">
            <img src="1.png" id="img-1" alt="img1" />
            <img src="2.png" alt="img2" />
            <img src="3.png" id="img-2" alt="img3" />
          </div>
          <div className="mi-flex1">
            <img src="4.png" id="img-1" alt="img4" />
            <img src="5.png" alt="img5" />
            <img src="6.png" id="img-2" alt="img6" />
          </div>
          <div className="mi-flex1" id="col-1">
            <img src="7.png" id="img-1" alt="img7" />
            <img src="8.png" alt="img8" />
            <img src="9.png" id="img-2" alt="img9" />
          </div>
        </div>
      </div>
      <div className="intro">
        <div className="intro-img" id="desktop-only">
          <img src="intro-img.jpg" className="intro-image" alt="intro" />
        </div>

        <div className="intro-text" id="about">
          <p className="intro-text-heading">
            Your Brand, Your Vision,
            <br /> Perfectly Transformed.
          </p>
          <div className="intro-img" id="mobile-only">
            <img src="intro-img.jpg" className="intro-image" alt="intro" />
          </div>
          <p className="intro-text-desc">
            Specializing in creative direction, hair and makeup, styling, and
            photoshoots, our comprehensive services ensure a cohesive and
            visually striking brand presentation. From concept development to
            execution, we transform ideas into compelling visual stories.
          </p>
        </div>
      </div>
      <div className="services" id="service">
        <div
          ref={containerRef}
          className={`container ${isScrolled ? "scrolled" : ""}`}
        >
          <div
            data-text="Creative-direction"
            style={{ "--r": "-15" }}
            className="glass"
          >
            <a href="#created-direction">
              <img src="3.png" alt="service1" />
            </a>
          </div>
          <div
            data-text="Hair,Makeup and Styling"
            style={{ "--r": "5" }}
            className="glass"
          >
            <a href="#hair-makeup">
              <img src="hair.png" alt="service2" />
            </a>
          </div>
          <div data-text="Shoots" style={{ "--r": "25" }} className="glass">
            <a href="#shoots">
              <img src="shoots.jpg" alt="service3" />
            </a>
          </div>
        </div>
      </div>
      <div className="service-main">
        <div className="service-heading">
          Elevate your business with our Experts
        </div>
        <div className="services-desc">
          <div className="service-exp" id="created-direction">
            <div className="service-img">
              <img src="creative-image 1.png"></img>
            </div>
            <div className="service-text">
              <div className="service-text-heading">Creative Direction</div>
              <p className="service-text-desc">
                We provide creative direction for brands, shaping the visual and
                conceptual aspects of their projects. Our services ensure
                cohesive and compelling brand narratives, from initial concept
                development to final execution. We bring your brand's vision to
                life with strategic planning and artistic guidance.
              </p>
            </div>
          </div>
          <div className="service-exp" id="hair-makeup">
            <div className="service-img" id="mobile-only">
              <img src="hair-makeup-image 1.png"></img>
            </div>
            <div className="service-text">
              <div className="service-text-heading">
                Hair, Makeup and Styling
              </div>
              <p className="service-text-desc">
                We offer expert hair, makeup, and styling services to elevate
                your brand's visual appeal. Our team ensures a polished and
                cohesive look that aligns with your brand identity. From
                photoshoots to promotional events, we create stunning styles
                that capture attention and enhance your brand narrative.
              </p>
            </div>
            <div className="service-img" id="desktop-only">
              <img src="hair-makeup-image 1.png"></img>
            </div>
          </div>
          <div className="service-exp" id="shoots">
            <div className="service-img">
              <img src="shoots-image 1.png"></img>
            </div>
            <div className="service-text">
              <div className="service-text-heading">Shoots</div>
              <p className="service-text-desc">
                We provide professional photoshoot services for brands, models,
                and portfolios, capturing your unique essence with stunning
                visuals. Our team handles everything from pre-production
                planning to final edits, ensuring a smooth and creative process.
                We deliver high-quality images that effectively convey your
                story and identity.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact" id="contact">
        <div className="contact1">
          <p>Let's shape & build your brand together</p>
        </div>
        <div className="contact2" id="talk">
          <button onClick={openWhatsapp}>Let's Talk</button>
        </div>
      </div>
      <div className="footer">
        <div>
          <div>
            <h1>Auburn</h1>
          </div>
          <div>
            <p className="grey">ⓒ Copyright 2024. All rights reserved</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Production;
