import Production from './Production';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Marketing from './Marketing';
import ScrollToTop from './ScrolltoTop';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/production" element={<Production />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
