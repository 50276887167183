import "./Home.css";
import RotatingCircle from "./RotatingCircle";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";
import "./Marketing.css";
function Home() {
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };
  function openWhatsapp() {
    window.open(
      "https://wa.me/9711541313?text=Hello%20Auburn,%20I%20am%20interested%20in%20your%20marketing%20services",
      "_blank"
    );
  }
  const [formData, setFormData] = useState({
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    // Validation for name
    if (name === "name") {
      error = /^[a-zA-Z\s]+$/.test(value) ? "" : "Enter a valid name";
    }

    // Validation for email
    if (name === "email") {
      error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ? ""
        : "Enter a valid email";
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Check if there are any validation errors
    if (formData.name.error || formData.email.error) {
      console.error("Form has validation errors");
      return; // Exit function if there are validation errors
    }

    // If validation passes, construct the URL with form data
    const url = `https://script.google.com/macros/s/AKfycbzzh2j81CF57sczSJDQ82n4VlRqZs4DpgOOA1QY0-B5c4-1yd4tdFVAy9bBd4sMvnpZKA/exec?name=${encodeURIComponent(
      formData.name.value
    )}&email=${encodeURIComponent(
      formData.email.value
    )}&message=${encodeURIComponent(formData.message)}`;

    // Execute the constructed URL
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("Google Sheet submission successful:", data);
        // Additional logic after successful Google Sheet submission
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error submitting to Google Sheet:", error);
        // Additional error handling logic
      });
  };
  return (
    <div className="Home">
      <nav className="navbar">
        <img src="logo.png" alt="Company Logo" className="logo" />
        <div className={`nav-links ${isMobile ? "mobile" : ""}`}>
          <Link to="/production">
            Production <span className="nav-co">04</span>
          </Link>
          <Link to="/marketing">
            Marketing <span className="nav-co">06</span>
          </Link>
          <a href="#about">
            About <span className="nav-co">us</span>
          </a>
          <a href="#contact">
            Contact <span className="nav-co">us</span>
          </a>
        </div>
        <div className="hamburger" onClick={handleToggle}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      <div className="top-div">
        <div className="headings">
          GROW YOUR &nbsp; BUSINESS WITH US
          <br />
          <span className="heading-sub">
            <p>
              Marketing &nbsp;&nbsp;|&nbsp;&nbsp; Production &nbsp;&nbsp;
              |&nbsp;&nbsp; Advetising &nbsp;&nbsp;| &nbsp;&nbsp;Branding
            </p>
          </span>
        </div>
        <div className="para">
          We are a full cycle Marketing and Production agency. We implement
          advertising projects from "A" to "Z" from marketing reserach to media
          <br />
          Tell us about your project and we will offer an effective solution!
        </div>
      </div>
      <div className="circle">
        <RotatingCircle></RotatingCircle>
      </div>
      <div className="service">OUR SERVICES</div>
      <div className="cardsdiv">
        <div className="card1">
          <ul class="cards">
            <li>
              <Link to="/production" class="card">
                <img src="image.png" class="card__image" alt="" />
                <div class="card__overlay">
                  <div class="card__header">
                    <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img class="card__thumb" src="logo.jpeg" alt="" />
                    <div class="card__header-text">
                      <h3 class="card__title">PRODUCTION</h3>
                      <span class="card__status"></span>
                    </div>
                  </div>
                  <p class="card__description">
                    Auburn Production specializes in delivering high-quality
                    production services for a wide range of industries.
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="card2">
          <ul class="cards">
            <li>
              <Link to="/marketing" class="card">
                <img src="test.png" class="card__image" alt="" />
                <div class="card__overlay">
                  <div class="card__header">
                    <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img class="card__thumb" src="logo.jpeg" alt="" />
                    <div class="card__header-text">
                      <h3 class="card__title">MARKETING</h3>
                      <span class="card__status"></span>
                    </div>
                  </div>
                  <p class="card__description">
                    Auburn Marketing is a dynamic and innovative marketing firm
                    dedicated to helping businesses elevate their brand presence
                    and achieve their growth goals.
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <h1 className="heading-about" id="about">
        About Us
      </h1>
      <div className="About">
        <div className="about-para">
          <p>
            Our task is not just to run social networks or launch advertising.
            We help businesses grow, enter new markets and expand boundaries,
            with the help of our competencies in marketing online advertising
            and design
          </p>
        </div>
        <div className="about-stats">
          <div class="stat">
            <h2>7+</h2>
            <p>years of work</p>
          </div>
          <div class="stat">
            <h2>24</h2>
            <p>professionals</p>
          </div>
          <div class="stat">
            <h2>132</h2>
            <p>successful cases</p>
          </div>
        </div>
      </div>
      <div class="scrolling-strip">
        <div class="scrolling-text">
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
          <span>• WE HELP BUSINESSES •</span>
        </div>
      </div>
      <div className="containers">
        <h1 id="contact" className="containers-heading">
          CONTACT WITH US
        </h1>
        <p className="containers-para">
          Leave your details and we will be in touch today!
        </p>
        <div className="form-container">
          <div className="form1">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Enter your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your Name"
                value={formData.name.value}
                onChange={handleChange}
                style={{
                  borderBottom: `1px solid ${
                    formData.name.error ? "red" : "white"
                  }`,
                }}
                required
              />
              {formData.name.error && (
                <span style={{ color: "red", fontSize: "0.7rem" }}>
                  {formData.name.error}
                </span>
              )}

              <label htmlFor="email">Enter your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your Email"
                value={formData.email.value}
                onChange={handleChange}
                style={{
                  borderBottom: `1px solid ${
                    formData.email.error ? "red" : "white"
                  }`,
                }}
                required
              />
              {formData.email.error && (
                <span style={{ color: "red", fontSize: "0.7rem" }}>
                  {formData.email.error}
                </span>
              )}

              <label htmlFor="message">
                Describe your project and the questions that interest you:
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Text here..."
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              ></textarea>

              <button type="submit" className="submit-btn">
                Let's Talk
              </button>
            </form>
          </div>
          <div className="image1">
            <img src="ananya.jpg" alt="Team Meeting" className="img-contact" />
          </div>
        </div>
      </div>
      <div className="contact" id="contact">
        <div className="contact1">
          <p>Let's shape & build your brand together</p>
        </div>
        <div className="contact2">
          <button onClick={openWhatsapp}>Let's Talk</button>
        </div>
      </div>
      <div className="footer">
        <div>
          <div>
            <h1>Auburn</h1>
          </div>
          <div>
            <p className="grey">ⓒ Copyright 2024. All rights reserved</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
export default Home;
